import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
import { image_url } from '../../config/Api';
import Cookies from 'js-cookie';



export const Modules = ({data}) => {
    var slider = [];

        if (data) {

            const section_data = data.find((row) => {
                if (row.typename == 'Educlerk Modules') {
                    return true;
                }
                else {
                    return false;
                }
            });

            if (section_data) {
                slider = section_data.slider;
            }
        }

    var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            arrows: true,
            loop: true,
            margin: 30,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


    return (

        <Fragment>
            <section className="educlerk__module__section pt-70 pb-120">

                <div className="container">

                    <div className="title-style-one">
                        <h3> Educlerk Modules</h3>
                        <p>
                            The software is usable in all organization
                        </p>
                    </div>

                    <div className="product-slider module__slider">
                        <Slider
                            {...settings}
                        >
                            {
                                slider.map((row, key) => {

                                    return (
                                        <Fragment key={key}>
                                            <div>
                                                <div className="our-product-slide module__slider__item">
                                                    <img src={`${image_url}${row.long_image}`} height="45px" class="mb-35"/>
                                                    <h5 class="mb-10">{row.bn_title}</h5>
                                                    <h3 class="mb-0">{row.title}</h3>

                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })

                            }
                        </Slider>
                    </div>

                </div>

            </section>
        


        </Fragment>
    )
}

