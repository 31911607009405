import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
const Content = ({ content }) => {
    const { i18n } = useTranslation();
    return (
        <Fragment>
          
            {i18n.resolvedLanguage === 'en' && <Fragment>
                {content ?
                    <InnerHTML html={content.description} />
                    :
                    ''}
            </Fragment>}
            {i18n.resolvedLanguage === 'bn' && <Fragment>
                {content ?
                    <InnerHTML html={content.bn_description} />
                    :
                    ''}
            </Fragment>}
        </Fragment>
    )
}

export default Content
