import React, { Fragment, useEffect, useState } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


  
export const HomeKPI = ({ data }) => {
    useEffect(() => {
        $(document).ready(function () {
            var counted = 0;
            $(window).scroll(function () {
                try {
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (counted == 0 && $(window).scrollTop() > oTop) {
                        $('.count').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            },

                                {

                                    duration: 2000,
                                    easing: 'swing',
                                    step: function () {
                                        $this.text(Math.floor(this.countNum));
                                    },
                                    complete: function () {
                                        $this.text(this.countNum);
                                        //alert('finished');
                                    }

                                });
                        });
                        counted = 1;
                    }
                } catch {
                    // console.log("Error")
                }
            });
        });
    });

    return (
        
        <Fragment>

            
            <section class="who__used__educlerk__section bg-one pt-70 pb-70">
                        <div class="container">
                            <div class="title-style-one">
                                <h3>Educlerk is Suitable for</h3>
                                <p>
                                    The software is usable in all organization
                                </p>
                            </div>
                            <div class="who-used-educlerk-content">
                                <div class="row">

                                    {data && data.map((row, key) => {
                                        return (
                                            <Fragment>
                                                {row.list && row.list.map((prow, key) => {

                                                    return (
                                                        <Fragment>

                                                            <div class="col-lg-4 mb-4">
                                                                <div class="who-used-single">
                                                                    <div class="d-flex gap-3 align-items-center">
                                                                        <img src={image_url + '' + prow.image} alt="" height="25" width="25"></img>
                                                                        <h6 class="mb-0">  {prow.title ? prow.title : ''}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </Fragment>
                                                    )
                                                })}

                                            </Fragment>
                                        )

                                    })}
                                    
                                </div>
                            </div>


                        </div>
                    </section>


            
                        

            

        </Fragment>
    )
}

