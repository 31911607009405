import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";



export const Ecosystem = ({data}) => {
    return (
        <Fragment>
            {data && data.map((row,key)=>{
                return(
                    <Fragment>

                        <section class="our__clients pt-70 pb-70 bg-two">
                            <div class="container">
                                <div class="title-style-one">

                                    <h3>{row.listcontent.title}</h3>
                                </div>
                                <div class="row">

                                {row.list && row.list.map((prow,key)=>{

                                    return(
                                        <Fragment>

                                            <div class="col-lg-2 mb-4">
                                                <div class="client-single">
                                                    <img src={image_url+''+prow.image} alt=""></img>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}




                                </div>
                            </div>
                        </section>
                      

                        
                    </Fragment>
                )
            })}


        </Fragment>
    )
}

