import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, PageContent, setPathLayout } from "../actions/Utils";
import Common from '../components/page/layout/Common';
import CommonWithSidebar from '../components/page/layout/CommonWithSidebar';





class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            page_content:{}
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const {location} = this.props.history;
        const {slug} = this.props.match.params;

            this.props.PageContent(slug).then((res)=>{

            });
    }
    componentDidUpdate=(prevProps)=>
    {
        const {slug} = this.props.match.params;
        const {slug:prevSlug} = prevProps.match.params;

        if(prevSlug!=slug)
        {

            this.props.PageContent(slug).then((res)=>{

            });
        }
    }
    render() {
        const {page_data}=this.props.page_content.content;
        console.log(page_data);

        if(page_data)
        {
            return (
                <Fragment>
                    {
                        
                        <Common page_data={page_data}/>
                      

                    }
                    
                </Fragment>
            )
        }
        return (
            <Fragment>
                <div class="ripple"></div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Page));