import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Content from '../Content';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { image_url } from "../../../config/Api";
import Htmlcontent from '../../../components/Htmlcontent';


const Common = ({ page_data }) => {
    const { title, bn_title } = page_data;
    /* const {contents :{short_description=""}={}}=page_data; */
    const { bread_curmbs } = page_data;
    const { image: banner_img = "" } = page_data;
    const { contents } = page_data;
    const { i18n } = useTranslation();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    console.log("contents =",contents);
    return (
        <Fragment>

            <div class="inner-header-bottom">
                <div class="container pt-60 pb-15">
                    <div class="row align-items-center mt-30">
                        <div class="col-lg-6">
                            <h3>{page_data.title}</h3>
                            <p>{contents.short_description}</p>
                        </div>
                        <div class="col-lg-6 pr-50 pl-50 text-center">
                           <img src={image_url+''+page_data.image} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>


            {page_data.section && page_data.section.map((prow,key)=>{
                return(
                    <Fragment>

                        {prow.contents[0].description &&<Fragment>
                            <Htmlcontent data={prow.contents[0].description} />
                        </Fragment>}
                    </Fragment>
                )
            })}




            <section className="who-we-are-page">
                <div className="container">
                    
                    <div className="inner-content">

                        <Content content={contents} />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Common
