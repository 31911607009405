
import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getAllNavigation, getHeaderNavigation, getTopNavigation, setLayout, setPath, setPathLayout, setSearchKey, getSetting,getHomeSliderData } from '../../../actions/Utils';
import Logo from '../../../img/logo.png';
import Slider from '../../../img/slider.png';
import Logo2 from '../../../img/logo.png';
import Doc from '../../../img/EduclerkBrochure.pdf';
import LangData from '../LangData';
import LangSelector from './LangSelector';
import MultiMenus from './Multimenu';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MultiMenusMobile from './MultimenuMobile';
import { base_path,image_url } from '../../../config/Api';

// import '../../../Styles/App.css'
// import '../../../Styles/Header.css'


class Commonheader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            top_menu: [],
            header_menu: [],
            megamenu_class: style.display_none,
            search: '',
            home_slider: {},
            accept_cookies: false,
            setting: {},
            menu_open: false, //menu open of off
            back_button: true, //show back button
            mobileMenuOpen: false,
           
            menu_show_row: -1, //sub menu logic
            is_home:'true'
        }
      
    }

    componentDidMount = () => {
        

        $(document).ready(function ($) {


            // var markerDiv = document.createElement("div");
            // markerDiv.innerHTML = "<div id='MyCoolDiv' style='color: #2b0808'></div>";
            // if(typeof(document.getElementById("playerContainer").value!=="undefined") || document.getElementById("playerContainer").value!==null)
            // {
            //     document.getElementById("playerContainer").appendChild(markerDiv.firstChild);
            // } 
            
            // // -------------------------------------------------------------^^^^^^^^^^^

            // setTimeout(function(){ 
            //     var myCoolDiv = document.getElementById("MyCoolDiv");
            //     if(typeof(document.getElementById("playerContainer").value!=="undefined") || document.getElementById("playerContainer").value!==null)
            //     {
            //         document.getElementById("playerContainer").removeChild(myCoolDiv);
            //     }
                
            // }, 1500);

            function loadScript(src) {
                return new Promise(function(resolve, reject){
                  var script = document.createElement('script');
                  script.src = src;
                  script.addEventListener('load', function () {
                    resolve();
                  });
                  script.addEventListener('error', function (e) {
                    reject(e);
                  });
                  document.body.appendChild(script);
                  document.body.removeChild(script);
                })
              };
            
            var pathname = window.location.pathname;
            if (!(pathname != '/' && pathname != '/home')) 
                loadScript('/theme_assets/js/revolution.slider.init.js');


            var wHeight = window.innerHeight;
            //search bar middle alignment
            $('#mk-fullscreen-searchform').css('top', wHeight / 2);
            //reform search bar
            $(window).resize(function () {
                wHeight = window.innerHeight;
                $('#mk-fullscreen-searchform').css('top', wHeight / 2);
            });
            // Search
            $('#search-button').click(function (e) {
                e.preventDefault();
                $("div.mk-fullscreen-search-overlay").addClass("mk-fullscreen-search-overlay-show");
            });
            $("a.mk-fullscreen-close").click(function (e) {
                e.preventDefault();
                $("div.mk-fullscreen-search-overlay").removeClass("mk-fullscreen-search-overlay-show");
            });
        });

        this.props.getSetting().then((res) => {
            this.setState({
                setting: res
            })
        });

        this.props.getHomeSliderData().then((res) => {
            this.setState({
                home_slider: res
                
            })
        });

        this.props.getHeaderNavigation({ navigation_name: 'header' }).then((res) => {
            this.setState({
                header_menu: res
            })

            // this.menuLoad();
        });

        this.props.getTopNavigation({ navigation_name: 'header toggle' }).then((res) => {
            this.setState({
                top_menu: res
            })

            // this.menuLoad();
        });

        var accept_cookiess = Cookies.get('accept_cookies');
        if (accept_cookiess && accept_cookiess == true) {
            this.setState({ accept_cookies: true });
        }

        // var login_token = Cookies.get('login_token');
        // if (!login_token || login_token !== localStorage.getItem('token')) {
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("member_id");
        //     localStorage.removeItem("member_pass");
        // }


    }

    componentDidUpdate = () => {
        // console.log('here i am');
        var pathname = window.location.pathname;
        if (pathname != '/' && pathname != '/home') {
            $(".forcefullwidth_wrapper_tp_banner").height("0")
            $(".forcefullwidth_wrapper_tp_banner").css('overflow','hidden')
        }else{
            $(".forcefullwidth_wrapper_tp_banner").height("auto")
            $(".forcefullwidth_wrapper_tp_banner").css('overflow','visible')

            function loadScript(src) {
                return new Promise(function(resolve, reject){
                  var script = document.createElement('script');
                  script.src = src;
                  script.addEventListener('load', function () {
                    resolve();
                  });
                  script.addEventListener('error', function (e) {
                    reject(e);
                  });
                  document.body.appendChild(script);
                  document.body.removeChild(script);
                })
              };
            
            loadScript('/theme_assets/js/revolution.slider.init.js');
        }
    }

    toggleMobileMenu = () => {
        this.setState(prevState => ({
            mobileMenuOpen: !prevState.mobileMenuOpen
        }));
    };

    closeMobileMenu = () => {
        this.setState({ mobileMenuOpen: false });
    };


    handleMenu = (status,key,total_header) => {

        if ($(window).width() <= 943) {//for mobile scene

            // console.log('samiur',total_header);

            if(key!=0 && key!=total_header-1){ 
                
                this.setState({
                    menu_open: status,
                    menu_show_row:key,
                    back_button: true,
                })
                

            }else{
                //if contact or home then close menu
                this.setState({
                    menu_open: false,
                    back_button:false
                    
                })

                $('body').removeClass('open-menu');
                $('.hamburger-menu .bar').toggleClass('animate');

            }
            

            

        }else{
            //except mobile scene
            this.setState({
                menu_open: status,
                menu_show_row:key,
                back_button: false,
            })

        }
        // console.log(key);
        
    }

    handleMenuBack = (status) => {
        //2nd layer back button

        if ($(window).width() <= 943) {
            //mobile scene
   
            this.setState({
                menu_open: status,
                back_button:true
                
            })

        }else{
            //except mobile scene
            this.setState({
                menu_open: status,
                back_button:false
                
            })

        }
        
        
        
    }

    handleMenuRemove = () => {
      //close menu for (home,contact) or 2nd layer link

        this.setState({
            menu_open: false,
            back_button:false
            
        })
        
        
        $('body').removeClass('open-menu');
        $('.hamburger-menu .bar').toggleClass('animate');
        
        
        
        
        
    }

    handleMenuON = () => {

        //menu on
        
        $('.hamburger-menu .bar').toggleClass('animate');
        this.setState({
            menu_open: false,
            back_button:false
            
        })
        
 
    }
    
    handleChange = event => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    };

    handleSubmit = event => {
        event.preventDefault();
        const { history, match } = this.props;

        const { search } = this.state;
        if (search.length) {
            this.setState({ search: '' });
            history.push(`${process.env.PUBLIC_URL}${match.url}search/${(search)}`);
            $("div.mk-fullscreen-search-overlay").removeClass("mk-fullscreen-search-overlay-show");
        }

    };
    acceptCookies = (e) => {
        e.preventDefault();
        Cookies.set('accept_cookies', 1);
        this.setState({ accept_cookies: true });
    }
    



    megaMenuShow = () => {
        // console.log("Hello Mobarak Yes hsow")
        this.setState({
            megamenu_class: style.display_block,
        })
    }
    megaMenuHide = () => {
        this.setState({
            megamenu_class: style.display_none,
        })
    }

    handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("member_id");
        localStorage.removeItem("member_pass");
        this.props.history.push(`${process.env.PUBLIC_URL}/`);
    }

    handleThirdLabel = (event, targetid) => {
        if ($(window).width() <= 943) {
            if ($("#" + targetid).children("ul").length) {
                setTimeout(() => {
                    $("#" + targetid).closest('ul').css("display", "block");
                }, 600);

                $("#" + targetid).find("ul").css("display", "block");
            }
            else {
                // console.log("show-on-mobile handleThirdLabel")
                setTimeout(() => {
                    $(".menu-mobile").toggleClass('menu-mobile-close');
                    $(".menu > ul").toggleClass('show-on-mobile');
                }, 300);

            }
        }

    }
    handleThirdList = () => {
        if ($(window).width() <= 943) {
            setTimeout(() => {
                $(".menu-mobile").toggleClass('menu-mobile-close');
                $(".menu > ul").toggleClass('show-on-mobile');
            }, 300);

        }

    }

    MobileMenuOff = () => {
        console.log('menu off');
        if ($(window).width() <= 943) {
            console.log('mobile scene');
            

            $("#navbarSupportedContent22").toggleClass('show');

        }

    }

    handleThirdLableMouseOut = (event, targetid) => {
        if ($(window).width() <= 943) {
            if ($("#" + targetid).children("ul").length) {
                setTimeout(() => {
                    $("#" + targetid).find("ul").css("display", "none");
                }, 300);
            }

        }
    }
    linkadd = (id) => {
        alert(id);
    }




    render() {

        
        const top_menu = this.props.headers.top_menu;
        
        const header_menu = this.props.headers.header_menu;
        const { mobileMenuOpen } = this.state;
        // console.log("data setting state =", this.state);
        // logo
        const { megamenu_class, setting, menu_open,menu_show_row,back_button,home_slider } = this.state;

        console.log('setting =',setting);
     
        // const lang = Cookies.get('lang')?Cookies.get('lang'):'bn';
        
        const lang = this.props.lang;
        var search_placeholder = lang == 'bn' ? 'অনুসন্ধান' : 'look out';

        var pathname = window.location.pathname;
        var headerclassname ='';
        var flag = true;
        if (base_path == '/') {
            if (pathname != '/' && pathname != '/home') {
                headerclassname = 'header-2';
                flag = false;
            }
        }
        else {
            if (pathname != '/' && pathname != '/home') {
                headerclassname = 'header-2';
                flag = false;
            }
        }
       

        console.log("flag =",flag);
        // console.log("Samiur =", header_menu);

        return (

            

            <Fragment>
            <header>
                <div class="header-topbar d-none d-sm-block">
                    <div class="container">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <ul class="nav">
                                    <li>Support: {setting.phone}</li>
                                    <li>Email: {setting.email}</li>
                                </ul>
                            </div>
                            <div>
                               

                                <a href={Doc} download="Doc" target='_blank' class="btn btn-primary mr-10">
                                                <i class="fa-solid fa-download  pr-5"></i> Download Brochure
                                            </a>
                                
                                <Link  className='btn btn-secondary'   to={{ pathname: `/contact` }} >Request Demo</Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="header__area header__transparent header__padding menu_area ">
                    <div class="container">
                        <div class="row align-items-center py-2">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
                                <div class="header__left d-flex">
                                    <div class="logo">
                                        {setting.logo && 
                                        <Link  to={{ pathname: `/` }} >
                                            <img src={setting.logo} alt="Logo" height='40'/>
                                        </Link>}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-8">
                                <div class="header__right d-flex justify-content-end align-items-center">
                                    {/* Desktop Main Menu */}
                                    <div className="main-menu d-none d-sm-block" >
                                                    <nav id="mobile-menu2">
                                                        <ul className="mb-0 ps-0">
                                                            {header_menu.length > 0 && (
                                                                <Fragment>
                                                                    {header_menu.map((row, key) => (
                                                                        <Fragment key={key}>
                                                                            {row.sub_nav.length > 0 ? (
                                                                                <li className="has-dropdown">
                                                                                    <a href="#" className="" onClick={this.closeMobileMenu} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        {row.title}
                                                                                    </a>
                                                                                    <ul className="submenu dropdown-menu">
                                                                                        {row.sub_nav.map((prow, pkey) => (
                                                                                            <li key={pkey}>
                                                                                                <Link to={{ pathname: `${prow.url}` }} className='dropdown-item' onClick={this.closeMobileMenu}>{prow.title}</Link>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </li>
                                                                            ) : (
                                                                                <li>
                                                                                    <Link to={{ pathname: `${row.url}` }} className='nav-link' onClick={this.closeMobileMenu}>{row.title}</Link>
                                                                                </li>
                                                                            )}
                                                                        </Fragment>
                                                                    ))}
                                                                </Fragment>
                                                            )}
                                                        </ul>
                                                    </nav>
                                                </div>

                                                {/* Mobile Sidebar Menu */}
                                                <div className="sidebar__menu d-xl-none">
                                                    <div className="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                                                        <a href="#" className="nav-link" onClick={this.toggleMobileMenu} aria-haspopup="true" aria-expanded={mobileMenuOpen ? 'true' : 'false'}>
                                                            <span class="line"></span>
                                                            <span class="line"></span>
                                                            <span class="line"></span>
                                                        </a>
                                                        
                                                    </div>
                                                </div>


                                               
                                                <div class="sidebar__area">
                                                    <div class="sidebar__wrapper">
                                                        <div class="sidebar__close">
                                                            <button class="sidebar__close-btn" id="sidebar__close-btn">
                                                                <span><i class="fas fa-times"></i></span>
                                                                <span>close</span>
                                                            </button>
                                                        </div>
                                                        <div class="sidebar__content">
                                                            <div class="logo mb-40">
                                                                {setting.logo && 
                                                                <Link  to={{ pathname: `/` }} >
                                                                    <img src={setting.logo} alt="Logo" height='40'/>
                                                                </Link>}
                                                            </div>
                                                            <div class="mobile-menu fix">
                                                                <ul className="mb-0 ps-0">
                                                                    {header_menu.length > 0 && (
                                                                        <Fragment>
                                                                            {header_menu.map((row, key) => (
                                                                                <Fragment key={key}>
                                                                                    {row.sub_nav.length > 0 ? (
                                                                                        <li className="has-dropdown">
                                                                                            <a href="#" className="" onClick={this.closeMobileMenu} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                {row.title}
                                                                                            </a>
                                                                                            <ul className="submenu dropdown-menu">
                                                                                                {row.sub_nav.map((prow, pkey) => (
                                                                                                    <li key={pkey}>
                                                                                                        <Link to={{ pathname: `${prow.url}` }} className='dropdown-item' onClick={this.closeMobileMenu}>{prow.title}</Link>
                                                                                                    </li>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li>
                                                                                            <Link to={{ pathname: `${row.url}` }} className='nav-link' onClick={this.closeMobileMenu}>{row.title}</Link>
                                                                                        </li>
                                                                                    )}
                                                                                </Fragment>
                                                                            ))}
                                                                        </Fragment>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                            <div class="mobile-content mt-10">
                                                                <div class="d-flex gap-2 justify-content-between">
                                                                    <Link  className='btn btn-primary'   to={{ pathname: `/contact` }} >Request Demo</Link>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header__area header__transparent header__padding menu_area menu--sticky header-sticky">
                    <div class="container">
                        <div class="row align-items-center py-2">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
                                <div class="header__left d-flex">
                                    <div class="logo">
                                        {setting.logo && 
                                        <Link  to={{ pathname: `/` }} >
                                            <img src={setting.logo} alt="Logo" height='40'/>
                                        </Link>}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-8">
                                <div class="header__right d-flex justify-content-end align-items-center">
                                    <div class="main-menu d-none d-sm-block">
                                        <nav class="mobile-menu2">
                                            
                                            <ul class="mb-0">

                                                        {header_menu.length ? <Fragment>
                                                        {header_menu.map((row, key) => {
                                                            return (
                                                                <Fragment>
                                                            
                                                                    {row.sub_nav.length ? <Fragment>
                                                                        <li class="has-dropdown">
                                                                            <a class="" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{row.title}</a>
                                                                            
                                                                            <ul class="submenu">
                                                                            {row.sub_nav.map((prow, pkey) => {
                                                                                return (
                                                                                    <Fragment>

                                                                                        <li>
                                                                                            <Link  className='dropdown-item' onClick={() => this.MobileMenuOff()}  to={{ pathname: `${prow.url}` }} >{prow.title}</Link>
                                                                                        </li>

                                                                                    </Fragment>
                                                                                        
                                                                                        )
                                                                                    })
                                                                            }
                                                                            </ul>
                                                                        </li>
                                                                        </Fragment> : <Fragment>
                                                                            <li>
                                                                                <Link  className='nav-link' onClick={() => this.MobileMenuOff()}  to={{ pathname: `${row.url}` }} >{row.title}</Link>
                                                                
                                                                            </li>
                                                                    </Fragment>}
             

                                                                </Fragment>
                                                            )
                                                        })}
                                                        </Fragment> : ''}
                                                    </ul>
                                        </nav>
                                    </div>
                                    <div class="sidebar__menu d-xl-none">
                                        <div class="sidebar-toggle-btn ml-30" id="sidebar-toggle2">
                                            <span class="line"></span>
                                            <span class="line"></span>
                                            <span class="line"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>



                

            </Fragment >

        );
    }
}

const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
    lang: state.utils.lang,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    setSearchKey: (requestData) => { dispatch(setSearchKey(requestData)) },
    getAllNavigation: (requestData) => dispatch(getAllNavigation(requestData)),
    getHomeSliderData: (requestData) => dispatch(getHomeSliderData(requestData)),
    getTopNavigation: (requestData) => dispatch(getTopNavigation(requestData)),
    getHeaderNavigation: (requestData) => dispatch(getHeaderNavigation(requestData)),
    getSetting: (requestData) => dispatch(getSetting(requestData)),

})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Commonheader));

const style = {
    display_none: {
        display: 'none'
    },
    display_block: {
        display: 'block'
    }
};