
import InnerHTML from 'dangerously-set-inner-html';
import $, { htmlPrefilter } from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFooterSection, getHomePageModal, newsSubscribe } from "../../actions/Utils";
import Htmlcontent from '../Htmlcontent';
import { host_name } from '../../config/Api';
import Cookies from 'js-cookie';
// import '../../Styles/Footer.css';
import LangData from './LangData';
import Logo from '../../img/logo.png';
class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            footerdata: {},
            validator: {
                name: '',
                email: '',
                concern: '',
                message: '',
            },
            message: '',
            message_type: '',
            home_modal: '',
            modal_show: false,
            modalLoad: 0,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        $(document).ready(function ($) {
            $('.copyright-year').html(new Date().getFullYear());
        });
        this.props.getFooterSection();

        setTimeout(() => {
            this.props.getHomePageModal().then((data) => {
                this.setState({
                    home_modal: data,
                    modal_show: data ? true : false,
                })
            });
        }, 500);
    }

    static getDerivedStateFromProps(props, state) {

        return {
            footerdata: props.footer,
            lang: props.lang,
            setting: props.setting,
        }
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    modalLoadcount = () => {
        this.setState({ modalLoad: 1 })
    }

    handleSubmit = () => {



        const userFormData = {

            email: this.email.value,

        }

        const resetValidator = {

            email: '',

        }
        var validation = {};

        if (userFormData.email == '') {
            validation.email = 'The email field is required.';
        }



        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

        }
        else {


            this.props.newsSubscribe(userFormData).then((res) => {
                this.email.value = '';

                this.setState({ message: res.data.success, message_type: 'success', validator: { ...resetValidator } })
            })
                .catch(error => {


                    if (typeof error.response.data.errors != "undefined") {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            validation[key] = value;
                        }
                        this.setState({ validator: validation })
                    }
                    else if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data)) {
                                html += `${value}`;
                            }

                        }


                    } else if (error.request) {

                        // console.log('error.request ',error.request);
                    } else {

                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { ...resetValidator } })
                    }

                })
        }

    }

    handleCloseModal = () => {

        this.setState({
            modal_show: false,
        })
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        return false;
        // if (event.keyCode  == 13 ) {
        //     event.preventDefault();
        //     return false;
        // }
    }
    render() {

        const { footerdata, modal_show, home_modal, modalLoad } = this.state;

        const { validator } = this.state;
        console.log("footerdata", footerdata);
        const lang = this.props.lang;
        const setting = this.props.setting;
        var placeholder_email_subscribe = lang === 'bn' ? "আপনার ইমেইল কি" : 'What’s your email';
        return (
            <Fragment>

                
                <footer>
                    <div class="container pb-30">
                        <div class="row">
                            <div class="col-lg-3">
                                <img src={Logo} alt="Logo" height="40" />
                                <div class="footer-social mt-30">
                                    <a href={setting.fb_link}><i class="fab fa-facebook-f"></i></a>
                                    <a href={setting.instagram_link}><i class="fa-brands fa-x-twitter"></i></a>
                                    <a href={setting.twitter_link}><i class="fa-brands fa-linkedin-in"></i></a>
                                    <a href={setting.youtube_link}><i class="fa-brands fa-youtube"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h4>Company</h4>
                                
                                <ul>

                                    {footerdata.footer_menu_1 && footerdata.footer_menu_1.map((row, key) => {
                                        return (
                                            <li key={key}>
                                                {row.link_type == 0 && <Link to={{
                                                    pathname: `${row.url}`,
                                                }} target="_blank">
                                                    
                                                    <Fragment>{row.title}</Fragment>
                                                </Link>}
                                                {row.link_type == 1 && <Link to={`${row.url}`}>
                                                <Fragment>{row.title}</Fragment>
                                                    
                                                </Link>}
                                            </li>
                                        )
                                    })}
                                    
                                </ul>
                            </div>
                            <div class="col-lg-3">
                                <h4>Support</h4>
                                <ul>

                                    {footerdata.footer_menu_2 && footerdata.footer_menu_2.map((row, key) => {
                                        return (
                                            <li key={key}>
                                                {row.link_type == 0 && <Link to={{
                                                    pathname: `${row.url}`,
                                                }} target="_blank">
                                                    
                                                    <Fragment>{row.title}</Fragment>
                                                </Link>}
                                                {row.link_type == 1 && <Link to={`${row.url}`}>
                                                <Fragment>{row.title}</Fragment>
                                                    
                                                </Link>}
                                            </li>
                                        )
                                    })}
                                    
                                </ul>
                            </div>
                            

                            <div className="col-lg-3">
                                <h4>Stay up to date</h4>
                                    <form onSubmit={(event) => this.handleFormSubmit(event)} class="subscrive">
                                        <div class="position-relative">

                                            {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                                                <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                                    {this.state.message}
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>}
                                                   
                                            {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                                                <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                                    {this.state.message}
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>}


                                            <input type="text" ref={(input) => this.email = input} className="form-control" placeholder="Your email address"  />

                                             
                                            <button type="button" onClick={this.handleSubmit}><i class="fa-regular fa-paper-plane"></i></button>
                                            {validator.email && <Fragment><br></br><span className="help-block form-text text-danger subscribe-error">{validator.email}</span></Fragment>}
                                        </div>
                                    </form>

                            </div>







                        </div>
                    </div>
                    <div class="copyright">
                        <div class="container text-center">
                            <p>Copyright © 2024 Educlerk. All rights reserved</p>
                        </div>
                    </div>
                </footer>

                


            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    footer: state.footer,
    lang: state.utils.lang,
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    getFooterSection: (requestData) => { dispatch(getFooterSection(requestData)) },
    newsSubscribe: (requestData) => dispatch(newsSubscribe(requestData)),
    getHomePageModal: (requestData) => dispatch(getHomePageModal(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

