
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
const LangData = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
        {t(props.keyname)}
    </Fragment>
  )
}
 
export default LangData;